<template>
    <div class="tw-flex tw-justify-center">
        <StatusBlock
            v-for="block in statusBloeckeMap.values()"
            :key="block.titel"
            class="colorGray tw-h-12 tw-w-56 tw-pl-2 tw-cursor-pointer"
            :titel="block.titel"
            :status="block.anzahl"
            :style="borderLeft + block.farbe"
            @click="clickStatusFilter(block)"
        />
    </div>
    <div class="tw-mt-20">
        <SwitchBadge
            v-for="badge in badgesLesen"
            :key="badge.text"
            v-model="badge.isActiveFilter"
            :color="badge.color"
            :text="badge.text"
            class="tw-mr-2 tw-mt-4 tw-cursor-pointer"
        />
        <div class="tw-inline tw-align-super">
            <span class="tw-mr-2">|</span>
        </div>
        <SwitchBadge
            v-for="badge in badges"
            :key="badge.text"
            v-model="badge.isActiveFilter"
            :color="badge.color"
            :text="badge.text"
            class="tw-mr-2 tw-mt-4 tw-cursor-pointer "
        />
    </div>

    <div class="tw-flex tw-mt-10 colorGray">
        <div class="tw-flex-initial tw-w-1/2 tw-text-left">
            {{ anzahlBenachrichtigungenText }}
        </div>
        <div class="tw-flex-initial tw-w-1/2 tw-text-right">
            <span
                class="tw-cursor-pointer tw-whitespace-nowrap tw-inline-block"
                @click="angezeigteGelesen"
            >
                <img
                    class="tw-inline tw-h-5 tw-w-5 tw-align-bottom"
                    src="/static/content/pics/fontawesome/envelope-open_71757a.svg"
                    alt=""
                >
                <span class="colorGray tw-ml-2">angezeigte gelesen</span>
            </span>
            <span
                class="tw-cursor-pointer tw-whitespace-nowrap tw-inline-block"
                @click="modalOpen = true"
            >
                <img
                    class="tw-inline tw-h-5 tw-w-5 tw-align-bottom tw-ml-4"
                    src="/static/content/pics/fontawesome/trash-alt_71757a.svg"
                    alt=""
                >
                <span class="colorGray tw-ml-2">angezeigte l&ouml;schen</span>
            </span>
            <span
                class="tw-ml-2 tw-cursor-pointer"
                title="Hilfe anzeigen"
                @click="hilfeOpen()"
            >
                <img
                    src="/static/content/pics/glyphicons-2-0/glyphicons-basic-635-circle-question.svg"
                    class="tw-inline tw-h-5 tw-w-5 tw-align-bottom"
                    alt="Hilfe"
                >
            </span>
        </div>
    </div>

    <div class="tw-mt-3">
        <div class="tw-bg-white tw-border tw-border-gray-300 tw-overflow-hidden sm:tw-rounded-md">
            <ul
                role="list"
                class="tw-divide-y tw-divide-gray-300"
            >
                <li v-if="!benachrichtigungenGeladen">
                    <LoadingSpinnerWithText
                        text="Einen Augenblick bitte ..."
                        class="tw-my-5"
                    />
                </li>
                <template v-else>
                    <BenachrichtigungenListeEntry
                        v-for="benachrichtigung in benachrichtigungen"
                        :key="benachrichtigung.id"
                        :benachrichtigung="benachrichtigung"
                        :benachrichtigung-loeschen-parent="deleteBenachrichtigung"
                        :benachrichtigung-gelesen-ungelesen="toggleBenachrichtigungGelesenUngelesen"
                    />
                </template>
                <li v-if="showLoadError">
                    <ErrorAlertWithAction
                        class="tw-m-4"
                        title="Fehler"
                        :button-title="retryButtonText"
                        :text="errorText"
                        :action="functionToRetry"
                        @close="showLoadError = false"
                    />
                </li>
            </ul>
        </div>
    </div>

    <div v-if="benachrichtigungenGeladen">
        <div v-if="weitereNachladen">
            <div class="tw-flex tw-w-full tw-justify-center tw-mt-6">
                <ButtonWithLoadingSpinner
                    text="Weitere Benachrichtigungen laden"
                    :show-spinner="!weitereBenachrichtigungenGeladen"
                    @click="benachrichtigungenNachladen"
                />
            </div>
            <div class="tw-flex tw-w-full tw-justify-center tw-mt-4">
                <a
                    class="tw-cursor-pointer tw-text-gray-700 tw-underline"
                    @click="alleLaden"
                >
                    Alle laden
                </a>
            </div>
        </div>
        <div
            v-else-if="!showLoadError"
            class="tw-flex tw-w-full tw-justify-center tw-mt-6"
        >
            <span v-if="benachrichtigungen.length > 0">Es wurden alle Benachrichtigungen geladen</span>
            <span v-else>Keine Benachrichtigungen gefunden</span>
        </div>
    </div>

    <ModalDialogOneFunction
        v-model="modalOpen"
        :parent-funktion="alleLoeschen"
        :titel="modalTitle"
        :content="modalText"
        :button-text="modalButtonLoeschen"
    />

    <ModalDialogOkButton
        v-model="hilfeOpenValue"
        :titel="hilfeTitel"
        :content="hilfeText"
        :button-text="hilfeButtonText"
    />
</template>

<script setup lang="ts">
import {
    buildBenachrichtigungenFromResponse,
    getBenachrichtigungIconTitle,
} from "../../utilities/Benachrichtigungen/BenachrichtigungForFrontEndMapper";
import {type Ref, computed, inject, ref, watch} from "vue";
import {keyEndpointFactory, keyNotificationService} from "../../injection-keys";
import {BaseException} from "../../BaseException";
import type {Benachrichtigung} from "../../models/api/benachrichtigung/Benachrichtigung";
import type {BenachrichtigungEndpoint} from "../../models/api/benachrichtigung/BenachrichtigungEndpoint";
import type {BenachrichtigungQuery} from "../../models/api/benachrichtigung/BenachrichtigungQuery";
import {BenachrichtigungTags} from "../../models/api/benachrichtigung/BenachrichtigungTags";
import BenachrichtigungenListeEntry from "./BenachrichtigungenListeEntry.vue";
import type {BenachrichtigungenResponse} from "../../models/api/benachrichtigung/BenachrichtigungenResponse";
import type {BenachrichtigungenViewModel} from "./BenachrichtigungenViewModel";
import ButtonWithLoadingSpinner from "../common/ButtonWithLoadingSpinner.vue";
import ErrorAlertWithAction from "../common/ErrorAlertWithAction.vue";
import type {HilfeEndpoint} from "../../models/api/hilfe/HilfeEndpoint";
import type {HilfeQuery} from "../../models/api/hilfe/HilfeQuery";
import type {HilfeRessource} from "../../models/api/hilfe/HilfeRessource";
import LoadingSpinnerWithText from "../common/LoadingSpinnerWithText.vue";
import type {Meta} from "../../models/api/benachrichtigung/Meta";
import ModalDialogOkButton from "../common/ModalDialogOkButton.vue";
import ModalDialogOneFunction from "../common/ModalDialogOneFunction.vue";
import type {NotificationEvent} from "../../services/NotificationService";
import {NotificationTypeEnum} from "../../services/NotificationTypeEnum";
import StatusBlock from "../common/StatusBlock.vue";
import SwitchBadge from "../common/SwitchBadge.vue";
import type {Tag} from "../../models/api/benachrichtigung/Tag";
import type {TagCount} from "../../models/api/benachrichtigung/TagCount";

interface IStatusBlock {
    titel: string;
    anzahl: number;
    farbe: string;
    tags: Array<Tag>;
    lesen: Array<string>;
}

enum StatusBlockName {
    UNGELESEN = "ungelesen",
    PERSOENLICH = "persoenlich",
    WARNUNG = "warnung",
}

interface Badge {
    text: string;
    color: string;
    tag: Tag;
    isActiveFilter: boolean;
}

const statusBloeckeMap: Ref<Map<StatusBlockName, IStatusBlock>> = ref(new Map<StatusBlockName, IStatusBlock>());
statusBloeckeMap.value.set(StatusBlockName.UNGELESEN, {
    "titel": "UNGELESEN",
    "anzahl": 0,
    "farbe": "#63759A",
    "tags": [],
    "lesen": ["ungelesen"],
});
statusBloeckeMap.value.set(StatusBlockName.PERSOENLICH, {
    "titel": "BETREFFEN MICH",
    "anzahl": 0,
    "farbe": "#63759A",
    "tags": ["persoenlich"],
    "lesen": ["ungelesen", "gelesen"],
});
statusBloeckeMap.value.set(StatusBlockName.WARNUNG, {
    "titel": "WARNUNGEN",
    "anzahl": 0,
    "farbe": "#f5c150",
    "tags": ["warnung"],
    "lesen": ["ungelesen", "gelesen"],
});

const anzahlBenachrichtigungenText: Ref<string> = ref("");
const benachrichtigungen: Ref<Array<BenachrichtigungenViewModel>> = ref([]);
const benachrichtigungenGeladen: Ref<boolean> = ref(false);
const weitereBenachrichtigungenGeladen: Ref<boolean> = ref(true);
let anzahlBenachrichtigungenGesamt: number = 50;
let anzahlGefilterte: number = 0;
let page: number = 1;
const modalOpen: Ref<boolean> = ref(false);
const showLoadError: Ref<boolean> = ref(false);
const hilfeOpenValue: Ref<boolean> = ref(false);
const modalTitle: string = "Achtung";
const modalText: string = "Möchten Sie die angezeigten Benachrichtigungen wirklich löschen?";
const modalButtonLoeschen: string = "Löschen";

const functionToRetry: Ref<() => void> = ref(() => {
    // do nothing
});

const errorText: Ref<string> = ref("");
const retryButtonText: Ref<string> = ref("");

const hilfeTitel: Ref<string> = ref("");
const hilfeText: Ref<string> = ref("<p></p>");
const hilfeButtonText: string = "OK";
const badges: Ref<Array<Badge>> = ref(new Array<Badge>());
const badgesLesen: Ref<Array<Badge>> = ref([
    {"text": "ungelesen", "color": "#63759A", "tag": "ungelesen", "isActiveFilter": false},
    {"text": "gelesen", "color": "#63759A", "tag": "gelesen", "isActiveFilter": false},
]);
const borderLeft: string = "border-left: 5px solid ";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const endpointFactory = inject(keyEndpointFactory)!;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const notificationService = inject(keyNotificationService)!;

const benachrichtigungenEndpoint: BenachrichtigungEndpoint = endpointFactory.getBenachrichtigungEndpoint();

const weitereNachladen
    = computed((): boolean => benachrichtigungen.value.length < anzahlGefilterte);

const getFilterArray = (): Array<string> => {
    const filterArray: Array<string> = [];

    badges.value.forEach((badge) => {
        if (badge.isActiveFilter) {
            filterArray.push(badge.tag);
        }
    });

    return filterArray;
};

const getFilterLesen = (): boolean | null => {
    let gelesen = false as boolean;
    let ungelesen = false as boolean;

    badgesLesen.value.forEach((badge) => {
        if (badge.tag === "ungelesen" && badge.isActiveFilter) {
            ungelesen = true;
        }
        if (badge.tag === "gelesen" && badge.isActiveFilter) {
            gelesen = true;
        }
    });

    if (gelesen && !ungelesen) {
        return true;
    }

    if (!gelesen && ungelesen) {
        return false;
    }
    return null;
};

const buildBadges = (tags: Array<Tag>): void => {
    const addBadge = (tag: Tag): void => {
        if (!badges.value.find((badge) => badge.tag === tag)) {
            badges.value.push({
                "text": getBenachrichtigungIconTitle(tag),
                "color": "#00c3d0",
                "tag": tag,
                "isActiveFilter": false,
            } as Badge);
        }
    };

    for (const tag of tags) {
        addBadge(tag);
    }
};

const setStatusBloeckeAndAnzahlBenachrichtigungenText = (meta: Meta): void => {
    anzahlBenachrichtigungenGesamt = meta.size;
    anzahlGefilterte = meta.size_filtered;

    if (anzahlBenachrichtigungenGesamt > 0) {
        anzahlBenachrichtigungenText.value = `${String(anzahlGefilterte)} von ${String(anzahlBenachrichtigungenGesamt)}`;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    statusBloeckeMap.value.get(StatusBlockName.UNGELESEN)!.anzahl = meta.size_ungelesen;

    let anzahlPersoenlich = 0;
    let anzahlWarnung = 0;
    for (const tagCount of meta.tagCount) {
        if (tagCount.tag === BenachrichtigungTags.PERSOENLICH) {
            anzahlPersoenlich = tagCount.anzahl;
        }
        if (tagCount.tag === BenachrichtigungTags.WARNUNG) {
            anzahlWarnung = tagCount.anzahl;
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    statusBloeckeMap.value.get(StatusBlockName.PERSOENLICH)!.anzahl = anzahlPersoenlich;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    statusBloeckeMap.value.get(StatusBlockName.WARNUNG)!.anzahl = anzahlWarnung;
};

const setTagsAndBuildBadges = (tagCounts: Array<TagCount>): void => {
    const tags: Array<Tag> = [];
    for (const tagCount of tagCounts) {
        if (!tags.includes(tagCount.tag)) {
            tags.push(tagCount.tag);
        }
    }

    statusBloeckeMap.value.forEach((statusBlock: IStatusBlock) => {
        statusBlock.tags.forEach((tag: Tag) => {
            if (!tags.includes(tag)) {
                tags.push(tag);
            }
        });
    });

    tags.sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));
    buildBadges(tags);
};

const setBenachrichtigungenResponseAndBenachrichtigungenRessources
    = (benachrichtigungenFromJsonAPI: Array<Benachrichtigung>, nachladen: boolean): void => {
        benachrichtigungenGeladen.value = true;
        if (nachladen) {
            weitereBenachrichtigungenGeladen.value = true;
        }
        benachrichtigungen.value = buildBenachrichtigungenFromResponse(benachrichtigungenFromJsonAPI);
    };

const loadBenachrichtigungenListe = (nachladen: boolean, alleLaden: boolean): void => {
    if (nachladen) {
        page += 1;
    } else {
        page = 1;
    }

    showLoadError.value = false;

    const filterArray = getFilterArray();
    const filterGelesen = getFilterLesen();

    const benachrichtigungQuery: BenachrichtigungQuery = {
        pageSize: alleLaden ? anzahlBenachrichtigungenGesamt : 50,
        page: page,
        filter: filterArray,
    };

    if (filterGelesen !== null) {
        benachrichtigungQuery.gelesen = filterGelesen;
    }
    benachrichtigungenEndpoint
        .getBenachrichtigungen(benachrichtigungQuery)
        .then((response: BenachrichtigungenResponse) => {
            const metaData = response.getMetaData();
            setStatusBloeckeAndAnzahlBenachrichtigungenText(metaData);
            setTagsAndBuildBadges(metaData.tagCount);
            setBenachrichtigungenResponseAndBenachrichtigungenRessources(response.getData(), nachladen);
        })
        .catch((ex: unknown) => {
            if (ex instanceof BaseException) {
                benachrichtigungenGeladen.value = true;
                errorText.value = ex.message;
                showLoadError.value = true;
                retryButtonText.value = "Erneut laden";
                if (nachladen) {
                    weitereBenachrichtigungenGeladen.value = true;
                    page--;
                    functionToRetry.value = (): void => {
                        weitereBenachrichtigungenGeladen.value = false;
                        loadBenachrichtigungenListe(true, alleLaden);
                    };
                } else {
                    functionToRetry.value = (): void => {
                        benachrichtigungenGeladen.value = false;
                        loadBenachrichtigungenListe(false, alleLaden);
                    };
                }
            }
        });
};

watch([badges, badgesLesen], (): void => {
    loadBenachrichtigungenListe(false, false);
}, {deep: true});

const clickStatusFilter = (statusBlock: IStatusBlock): void => {
    for (const badge of badges.value.values()) {
        badge.isActiveFilter = false;
    }

    for (const tagBezeichnung of statusBlock.tags) {
        const badge = badges.value.find((badge) => badge.tag === tagBezeichnung);
        if (badge) {
            badge.isActiveFilter = true;
        }
    }

    for (const badge of badgesLesen.value) {
        badge.isActiveFilter = false;
    }
    for (const tag of statusBlock.lesen) {
        const resultEntry = badgesLesen.value.find((badge) => badge.tag === tag);
        if (resultEntry) {
            resultEntry.isActiveFilter = true;
        }
    }
};

const benachrichtigungenNachladen = (): void => {
    weitereBenachrichtigungenGeladen.value = false;
    loadBenachrichtigungenListe(true, false);
};
const alleLaden = (): void => {
    benachrichtigungenGeladen.value = false;
    loadBenachrichtigungenListe(false, true);
};

const alleLoeschen = (): void => {
    benachrichtigungenEndpoint
        .deleteAll()
        .then(() => {
            loadBenachrichtigungenListe(false, false);
        })
        .catch((ex: unknown) => {
            if (ex instanceof BaseException) {
                notificationService
                    .notifyForEevent({type: NotificationTypeEnum.ERROR, text: ex.message} as NotificationEvent);
            }
        });
};

const toggleBenachrichtigungGelesenUngelesen = (id: string): void => {
    benachrichtigungenEndpoint
        .toggleBenachrichtigungGelesenUngelesen(id)
        .then(() => {
            loadBenachrichtigungenListe(false, false);
        })
        .catch((ex: unknown) => {
            if (ex instanceof BaseException) {
                notificationService
                    .notifyForEevent({type: NotificationTypeEnum.ERROR, text: ex.message} as NotificationEvent);
            }
        });
};

const deleteBenachrichtigung = (id: string): void => {
    benachrichtigungenEndpoint
        .deleteBenachrichtigung(id)
        .then(() => {
            loadBenachrichtigungenListe(false, false);
        })
        .catch((ex: unknown) => {
            if (ex instanceof BaseException) {
                notificationService
                    .notifyForEevent({type: NotificationTypeEnum.ERROR, text: ex.message} as NotificationEvent);
            }
        });
};

const angezeigteGelesen = (): void => {
    if (!(benachrichtigungen.value.length > 0)) {
        return;
    }
    benachrichtigungenEndpoint
        .setAlleGelesen()
        .then(() => {
            loadBenachrichtigungenListe(false, false);
        })
        .catch((ex: unknown) => {
            if (ex instanceof BaseException) {
                notificationService
                    .notifyForEevent({type: NotificationTypeEnum.ERROR, text: ex.message} as NotificationEvent);
            }
        });
};

loadBenachrichtigungenListe(false, false);

const hilfeOpen = (): void => {
    const hilfeEndpoint: HilfeEndpoint = endpointFactory.getHilfeEndpoint();
    const hilfeQuery: HilfeQuery = {hilfeId: "benachr_liste_loeschen"};
    hilfeEndpoint
        .getHilfeRessource(hilfeQuery)
        .then((hilfe: HilfeRessource) => {
            hilfeTitel.value = hilfe.getTitel();
            hilfeText.value = hilfe.getInhalt();
            hilfeOpenValue.value = true;
        })
        .catch((ex: unknown) => {
            if (ex instanceof BaseException) {
                notificationService
                    .notifyForEevent({type: NotificationTypeEnum.ERROR, text: ex.message} as NotificationEvent);
            }
        });
};
</script>

<style scoped>
    .colorGray {
        color: #71757a;
    }
</style>

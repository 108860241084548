<template>
    <li class="hover:tw-bg-hiorg-teal-100">
        <div class="tw-flex tw-items-center tw-px-4 tw-py-4 sm:tw-px-6">
            <div class="tw-min-w-0 tw-flex-1 tw-flex tw-items-center">
                <div
                    class="tw-flex-shrink-0 tw-cursor-pointer"
                    @click="onClickBenachrichtigungListe(benachrichtigung.id)"
                >
                    <div class="tw-pt-1 tw-relative">
                        <span
                            v-if="!benachrichtigung.gelesen"
                            class="tw-absolute tw-top-1 tw-left-0 tw-block tw-h-4 tw-w-4 tw-rounded-full"
                            style="background-color: #00c3d0;"
                        />
                        <div
                            class="tw-flex tw-items-center tw-justify-center tw-ml-5 tw-h-16 tw-w-16 tw-rounded-full"
                            :style="iconBgColor + benachrichtigung.iconBgColor"
                        >
                            <img
                                class="tw-h-8 tw-w-8"
                                :title="benachrichtigung.iconTitel"
                                :src="iconPfad + benachrichtigung.iconBezeichnung"
                                alt=""
                            >
                        </div>
                    </div>
                </div>
                <div class="tw-min-w-0 tw-flex-1 tw-pl-4 md:tw-grid md:tw-grid-cols-2">
                    <div>
                        <p
                            :title="!benachrichtigung.gelesen
                                ? 'Benachrichtigung als gelesen markieren'
                                : 'Benachrichtigung als ungelesen markieren'"
                            class="tw-mt-2 tw-flex tw-items-center tw-cursor-pointer"
                            @click="onClickBenachrichtigungListe(benachrichtigung.id)"
                        >
                            <span>{{ benachrichtigung.content }}</span>
                        </p>
                        <form
                            v-if="benachrichtigung.aktionTyp !== null"
                            :key="benachrichtigung.id"
                            ref="aktionFormListe"
                            class="tw-inline-block"
                            :action="aktionUrl + benachrichtigung.aktionTyp"
                            method="post"
                        >
                            <a
                                v-if="benachrichtigung.aktionText !== null"
                                class="tw-cursor-pointer tw-underline colorGray"
                                @click="formSubmit()"
                            >
                                {{ benachrichtigung.aktionText }} >
                            </a>
                            <input
                                v-for="[key, value] in benachrichtigung.aktionData.entries()"
                                :key="key"
                                type="hidden"
                                :name="'data[' + key + ']'"
                                :value="value"
                            >
                        </form>
                        <div class="tw-mt-2">
                            <span
                                v-for="tag in benachrichtigung.tags"
                                :key="tag"
                                class="tw-inline-flex tw-items-center tw-justify-center tw-tracking-wide tw-px-3
                                       tw-py-0.5 tw-rounded-full tw-text-sm tw-font-bold first:tw-ml-0 tw-ml-2
                                       tagColor"
                            >
                                {{ getIconTitel(tag) }}
                            </span>
                        </div>
                    </div>
                    <div class="tw-flex tw-justify-end tw-items-end">
                        <div
                            class="tw-flex tw-justify-end tw-items-end colorGray tw-whitespace-nowrap"
                        >
                            {{ benachrichtigung.erstelltAm }}
                        </div>
                        <div class="tw-flex tw-items-end tw-justify-end tw-ml-4 tw-w-20">
                            <LoadingSpinner
                                v-show="deleting"
                                class="tw-mr-2"
                            />
                            <TrashButton
                                @click="onClickDelete(benachrichtigung.id)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script setup lang="ts">
import {type Ref, ref, useTemplateRef} from "vue";
import type {BenachrichtigungenViewModel} from "./BenachrichtigungenViewModel";
import LoadingSpinner from "../common/LoadingSpinner.vue";
import TrashButton from "../common/TrashButton.vue";
import {getBenachrichtigungIconTitle} from "../../utilities/Benachrichtigungen/BenachrichtigungForFrontEndMapper";

const props = defineProps<{
    benachrichtigung: BenachrichtigungenViewModel;
    benachrichtigungLoeschenParent: (id: string) => void;
    benachrichtigungGelesenUngelesen: (id: string) => void;
}>();

const iconPfad: string = "/static/content/pics/fontawesome/";
const iconBgColor: string = "background-color: ";
const aktionUrl: string = "aktion.php?aktion=";
const aktionFormListe = useTemplateRef<HTMLFormElement>("aktionFormListe");
const deleting: Ref<boolean> = ref(false);

const onClickDelete = (id: string): void => {
    deleting.value = true;
    props.benachrichtigungLoeschenParent(id);
};

const formSubmit = (): void => {
    aktionFormListe.value?.submit();
};

const getIconTitel = (tag: string): string => getBenachrichtigungIconTitle(tag).toLocaleUpperCase();


const onClickBenachrichtigungListe = (id: string): void => {
    props.benachrichtigungGelesenUngelesen(id);
};

</script>

<style scoped>
    .colorGray {
      color: #71757a;
    }
    .tagColor {
      color:#63759a;
      background-color: #eff1f4;
    }
</style>

import ApiAction from "../../common/coloquent/ApiAction";
import type FreieBenachrichtigungApiResult from "./FreieBenachrichtigungApiResult";

export default class FreieBenachrichtigungApiAction extends ApiAction<FreieBenachrichtigungApiResult> {
    protected jsonApiType = "benachrichtigung_freie";

    protected pageSize = 1;

    public override getJsonApiBaseUrl(): string {
        return "/api_private";
    }

    public setEmpfaenger(empfaenger: Array<string>): void {
        this.setAttribute("empfaenger", empfaenger);
    }

    public setSendPush(sendPush: boolean): void {
        this.setAttribute("send_push", sendPush);
    }

    public setText(text: string): void {
        this.setAttribute("text", text);
    }
}
